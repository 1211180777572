$favorite-content-width: 15rem;
$favorite-content-min-height: 7rem;
$favorite-content-max-height: 15rem;

.favorite-button {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;

  &--active {
    .favorite-button__overlay,
    .favorite-button__content {
      display: block !important;
    }
  }

  & &__icon {
    &--big i {
      font-size: $font-size-28;
    }

    &:hover {
      color: var(--color-green);
      cursor: pointer;
    }
  }

  & &__overlay {
    display: none;
    position: absolute;
    top: -2rem;
    right: -2rem;
    width: calc($favorite-content-width + 5rem);
    height: calc($favorite-content-min-height + 5rem);
    z-index: 41;
  }

  & &__content {
    display: none;
    position: absolute;
    top: 1rem;
    right: 1rem;
    padding: 1rem;
    width: $favorite-content-width;
    min-height: $favorite-content-min-height;
    max-height: $favorite-content-max-height;
    background-color: var(--color-white);
    border-radius: var(--border-radius-md);
    border: 1px solid var(--color-grey);
    box-shadow: 0 0 5px var(--color-grey);
    color: var(--color-green);
    overflow: hidden;
    overflow-y: auto;
    z-index: 42;
    user-select: none;

    &::-webkit-scrollbar {
      width: 5px;
    }

    &::-webkit-scrollbar-thumb {
      border: 0 solid transparent;
      border-top-left-radius: calc(var(--border-radius-md) / 4);
      border-bottom-left-radius: calc(var(--border-radius-md) / 4);
      -webkit-box-shadow: inset 0 0 0 3px var(--color-green);
      -moz-box-shadow: inset 0 0 0 3px var(--color-green);
      box-shadow: inset 0 0 0 3px var(--color-green);
    }

    &::-webkit-scrollbar-track {
      margin-top: var(--border-radius-md);
    }

    &::-webkit-scrollbar-track {
      margin-bottom: var(--border-radius-md);
    }

    > :not(:last-child) {
      margin-bottom: 1rem;
    }

    .favorite-create {
      position: relative;

      input[type=text] {
        margin: 0;
        padding: .25rem 1rem .25rem 2.5rem;
        line-height: 1.5;
        color: var(--color-midnight-light);
      }

      &__icon {
        position: absolute;
        top: 0;
        bottom: 0;
        left: .75rem;
        display: inline-flex;
        align-items: center;
        font-size: $font-size-22;
        color: var(--color-midnight-light);
        cursor: text;
      }
    }

    .favorite-item {
      display: flex;
      flex-direction: row;
      align-items: center;
      color: var(--color-midnight-light);
      cursor: pointer;

      &:hover {
        color: var(--color-green);
      }

      &__name {
        margin-left: .5rem;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
  }
}
