.value-copier {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: var(--spacing-sm);
  padding: .5rem 1rem;
  border-radius: var(--border-radius-md);
  background-color: var(--color-ocean-lighter);
  color: var(--color-ocean);
  font-weight: $font-weight-bold;

  &__value {
    overflow: hidden;
    text-overflow: ellipsis;
  }

  &__icon {
    color: var(--color-midnight-light);

    i {
      font-size: $font-size-20;
    }
  }
}
