.progress-bar {
    display: flex;
    align-items: center;
  
    &.progress-bar-block {
      width: 25rem;
      height: 3rem;
      margin-bottom: 3rem;
      padding: .75rem 1.5rem;
      background: var(--color-white);
      box-shadow: $box-shadow;
      border-radius: var(--border-radius-xs);
    }
  
    .progress-bars {
      position: relative;
      width: 60%;
      height: .5rem;
  
      > span {
        position: absolute;
        top: 0;
        left: 0;
        max-width: 100%;
        border-radius: 3px;
        border: .25rem solid var(--color-green);
        background-color: var(--color-green);
  
        &.progress-bars-back {
          width: 100%;
          opacity: 0.25;
        }
  
        &.progress-bars-track {
          width: 33%;
          transition: width 0.2s ease-in-out;
        }
      }
    }
  
    .progress-state {
      margin: auto auto auto 1.5rem;
      font-size: $font-size-12;
      color: var(--color-midnight-light);
    }
  
    .progress-next {
      margin-right: -.5rem;
      color: var(--color-midnight-light);
      cursor: pointer;
    }
  
    @media #{$screen-s} {
      width: auto;
    }
  }
  