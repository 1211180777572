$config-header-height: 4.5rem;

.config-header {
  display: flex;
  justify-content: space-between;
  height: $config-header-height;
  padding: 0 var(--spacing-lg);
  color: var(--color-midnight);
  box-shadow: 2px 2px 6px var(--color-grey);
  align-items: center;

  &__menu {
    display: flex;
    position: relative;
    align-items: center;
    height: 100%;
    margin-right: 1rem;

    &__label {
      display: flex;
      align-items: center;
      height: 100%;
      padding: 0 1.5rem;
      font-size: $font-size-14;
      font-weight: $font-weight-bold;
      text-transform: uppercase;
      text-decoration: none;
      text-align: center;
      cursor: pointer;

      > :not(:last-child) {
        margin-right: .5rem;
      }
    }

    &__content {
      display: none;
      position: absolute;
      top: $config-header-height;
      background-color: var(--color-white);
      border: 1px solid var(--color-grey);
      min-width: 17rem;
      z-index: 16;

      ul li.config-header__menu__content__row {
        position: relative;
        display: flex;
        align-items: center;
        font-size: $font-size-14;
        color: var(--color-midnight);

        &:hover,
        &.config-header__menu__content__row--active {
          color: var(--color-green);

          .config-header__menu__content__row__link svg path {
            fill: var(--color-green);
          }
        }

        .config-header__menu__content__row__link {
          display: flex;
          align-items: center;
          width: 100%;
          padding: 1.5rem 3rem;
          line-height: 1.5;
          text-decoration: none;
          cursor: pointer;

          span {
            display: flex;
          }

          i {
            margin: 0 0.5rem;
            font-size: $font-size-20;
          }

          svg {
            fill: currentColor;
            margin: 0 0.75rem;
            width: 1.5rem;
            height: 1.5rem;
          }
        }
      }
    }

    &:hover {
      .config-header__menu__label {
        background-color: var(--color-grey);
        color: var(--color-green);
      }

      .config-header__menu__content {
        display: block;
      }
    }
  }

  &__buttons {
    display: flex;
    align-items: center;

    &__label {
      display: inline-flex;
      align-items: center;
      color: var(--color-midnight);
      font-size: $font-size-14;
      font-weight: $font-weight-regular;

      i {
        margin-right: .25rem;
      }
    }

    .split-button,
    .inline-buttons button,
    .inline-buttons a {
      margin-left: 1rem;
      margin-right: 0;
    }

    .split-button {
      display: none;
    }

    .inline-buttons {
      display: inline-flex;

      a {
        text-decoration: none;
      }
    }
  }
}

/* RESPONSIVE */
@media #{$screen-s}, #{$screen-m} {
  .config-header {
    &__menu {
      &__label {
        padding: 0 1rem;
        font-size: $font-size-12;
      }
    }

    &__buttons {
      button.btn-big {
        padding: 1rem;
      }

      .split-button {
        display: inline-flex;
      }

      .inline-buttons {
        display: none;
      }
    }
  }
}

@media #{$screen-s} {
  .config-header {
    padding: 0 1rem;

    &__buttons {
      &__label {
        max-width: 8rem;
      }
    }
  }
}
