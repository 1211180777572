.taxonomy-settings {
  display: flex;
  justify-content: flex-end;
  align-items: center;

  & &__items {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: var(--spacing-xs) var(--spacing-md);

    .setting-item {
      display: flex;
      align-items: center;

      &__content {
        display: flex;
        align-items: center;
        color: var(--color-midnight-light);

        &__label {
          margin-right: .75rem;
          font-weight: $font-weight-bold;
        }

        &__icon {
          margin-left: .5rem;

          &--reversed {
            transform: rotateX(180deg);
          }

          i {
            font-size: $font-size-20;
          }
        }

        .input-select select {
          border: 1px solid var(--color-grey);
          border-radius: var(--border-radius-md);
          font-weight: $font-weight-semi-bold;
          font-size: 1rem;
          max-width: 10rem;
        }

        &--active {
          .input-select select {
            color: var(--color-green);
          }
        }

        &__field {
          display: flex;
          padding: .4rem .5rem;
          border: 1px solid var(--color-grey);
          border-radius: var(--border-radius-md);

          &__link {
            padding: 0 .5rem;
            cursor: pointer;

            &:hover,
            &--active {
              color: var(--color-green);
            }
          }

          &__icon i {
            font-size: $font-size-18;
          }
        }
      }
    }
  }
}
