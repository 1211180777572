.relevancy-target {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  justify-content: space-evenly;
  margin-bottom: 3rem;

  .relevancy-target-main {
    width: 40rem;
    max-width: 100%;
    margin-bottom: 2rem;

    .relevancy-target-main-title {
      font-size: $font-size-28;
      margin-bottom: 4rem;
      font-weight: $font-weight-bold;
      letter-spacing: 0.2pt;
      color: var(--color-midnight-light);
      text-align: center;
    }
  }

  .relevancy-target-result {
    display: flex;
    justify-content: center;
    flex-direction: column;
    width: 100%;

    .relevancy-target-result-loader {
      margin-top: 5rem;
    }

    .relevancy-target-result-content {
      display: flex;
      align-items: center;
      justify-content: center;

      .relevancy-target-result-bar {
        position: relative;
        width: 70%;
        height: 3rem;

        @media #{$screen-s} {
          width: 90%;
        }

        span {
          position: absolute;
          top: 0;
          left: 0;
          max-width: 100%;
          border-radius: var(--border-radius-xl);

          &.back {
            width: 100%;
            height: 100%;
            background-color: var(--color-grey);
            border: 1.5rem solid var(--color-grey);
          }

          &.track {
            width: 0;
            height: 100%;
            transition: width 0.2s ease-in-out;
            border: 1.5rem solid var(--color-green);
          }
        }
      }

      .relevancy-target-result-value {
        position: absolute;
        font-size: $font-size-20;
        font-weight: $font-weight-semi-bold;
        color: var(--color-midnight);
      }
    }
  }
}

.relevancy-tag-cloud {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  height: 23rem;
  overflow: auto;

  span {
    margin: 0 1rem;
    display: flex;
    align-items: center;
    line-height: 1.6;
  }

  .tag-cloud-small {
    font-weight: $font-weight-light;
    font-size: $font-size-16;
    color: var(--color-midnight-light);
  }

  .tag-cloud-normal {
    font-weight: $font-weight-regular;
    font-size: $font-size-20;
    color: var(--color-midnight);
  }

  .tag-cloud-big {
    font-weight: $font-weight-semi-bold;
    font-size: $font-size-28;
    color: var(--color-midnight-dark);
  }

  .tag-cloud-extra-big {
    font-weight: $font-weight-bold;
    font-size: $font-size-34;
    color: var(--color-green);
  }
}
