$activation-platforms-width: 18rem;
$activation-platforms-height: 20rem;

.activation-platforms {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;

  & &__button {
    display: inline-flex;

    button i:hover {
      color: inherit;
    }

    @media #{$screen-s}, #{$screen-m} {
      button {
        background-color: unset;
        border: 0;
        color: inherit;
        padding: 0;

        i {
          margin: 0;

          &:hover {
            color: var(--color-green);
          }
        }

        span {
          display: none;
        }
      }
    }
  }

  & &__overlay {
    display: none;
    position: absolute;
    top: -3rem;
    right: -3rem;
    width: calc($activation-platforms-width + 6rem);
    height: calc($activation-platforms-height + 6rem);
    z-index: 51;
  }

  & &__content {
    display: none;
    position: absolute;
    top: 1rem;
    right: 1rem;
    padding: .5rem;
    width: $activation-platforms-width;
    max-height: $activation-platforms-height;
    background-color: var(--color-white);
    border-radius: var(--border-radius-md);
    border: 1px solid var(--color-grey);
    box-shadow: 0 0 5px var(--color-grey);
    overflow: hidden;
    overflow-y: auto;
    z-index: 52;

    &::-webkit-scrollbar {
      width: 5px;
    }

    &::-webkit-scrollbar-thumb {
      border: 0 solid transparent;
      border-top-left-radius: calc(var(--border-radius-md) / 4);
      border-bottom-left-radius: calc(var(--border-radius-md) / 4);
      -webkit-box-shadow: inset 0 0 0 3px var(--color-green);
      -moz-box-shadow: inset 0 0 0 3px var(--color-green);
      box-shadow: inset 0 0 0 3px var(--color-green);
    }

    &::-webkit-scrollbar-track {
      margin-top: var(--border-radius-md);
    }

    &::-webkit-scrollbar-track {
      margin-bottom: var(--border-radius-md);
    }

    .platform-item {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      padding: 0.5rem 1rem;
      background-color: var(--color-grey-light);
      border-radius: var(--border-radius-md);

      &:not(:last-child) {
        margin-bottom: .5rem;
      }

      &:hover {
        background-color: var(--color-grey);
      }

      &__info {
        .platform-item__name {
          display: block;
          margin-bottom: 0.25rem;
        }

        .platform-item__value {
          font-weight: $font-weight-bold;
          color: var(--color-green);
        }
      }

      &__icon {
        padding: 0.5rem;

        i {
          font-size: $font-size-20;
        }

        &:hover i {
          color: var(--color-green);
        }
      }
    }
  }

  &--active {
    .activation-platforms__overlay,
    .activation-platforms__content {
      display: block;
    }
  }
}
