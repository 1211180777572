.btn-campaign-brief {
    position: fixed;
    display: flex;
    align-items: center;
    right: 0;
    bottom: 0;
    width: 4rem;
    height: 4rem;
    margin: 1.5rem;
    background-color: var(--color-green);
    border: 1px solid var(--color-green);
    color: var(--color-white);
    box-shadow: $box-shadow;
    border-radius: var(--border-radius-xl);
    transition: all .15s ease;
    cursor: pointer;
    overflow: hidden;

    & .btn-campaign-brief-text {
        font-weight: 700;
        font-size: $font-size-16;
    }

    & i {
        font-size: $font-size-28;
    }

    & > :first-child {
        display: inline-flex;
        align-items: center;
        gap: 1rem;
        position: absolute;
        left: .9rem;
        width: 18rem;
    }

    &:hover {
        width: 18rem;

        & > :first-child {
            justify-content: center;
            left: 0;
        }
    }

    &:active {
        transform: scale(.9);
    }
}

.btn-yes-toggle {
    background-color: transparent;
    border: 1px solid var(--color-midnight);
    color: var(--color-midnight);

    &--checked {
        background-color: var(--color-midnight);
        border: 1px solid var(--color-midnight);
        color: var(--color-white);
    }
}

.btn-clear-all {
    &:hover > i {
        color: var(--color-red);
    }
}
