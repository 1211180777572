$element-details-margin-top: 4rem;

.wrapper {
  @for $i from 1 through 10 {
    > :nth-child(#{$i}) {
      .element-details {
        .details__skin {
          position: fixed;
          top: calc(var(--main-header-height) + calc(var(--main-alert-bar-height) * calc($i - 2)));
        }

        .details__main {
          position: sticky;
          top: calc($element-details-margin-top + var(--main-header-height) + calc(var(--main-alert-bar-height) * calc($i - 2)));

        }

        @media #{$screen-s}, #{$screen-m}, #{$screen-l} {
          .details__skin {
            position: absolute;
            top: 0;
          }

          .details__main {
            position: initial;
            width: 100%;
            max-width: unset;
          }
        }
      }
    }
  }
}

.element-details {
  max-width: 80rem;
  margin: auto;

  .details__skin {
    display: block;
    width: 100%;
    height: 100%;
    left: 0;
    z-index: -1;
    background: radial-gradient(circle at -2rem -2rem, var(--color-grey) 34.9rem, var(--color-white) 35rem);
    box-shadow: $box-shadow;

    svg {
      position: absolute;
      top: 1rem;
      left: -2rem;
      width: 18rem;
      opacity: .3;
    }
  }

  .details__main {
    left: 0;
    min-width: 25rem;
    max-width: 25rem;
    margin: 0 auto;
    border-radius: var(--border-radius-xl);
    box-shadow: $box-shadow;
    background-color: var(--color-white);
    overflow: hidden;

    &__row {
      padding: 2rem;

      &:not(:first-child) {
        border-top: 1px solid var(--color-grey);
      }
    }

    &__header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 1.5rem;

      &__actions {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        flex: 1;
        color: var(--color-midnight-light);

        > :not(:first-child) {
          margin-left: .25rem;
        }

        &__item {
          display: inline-flex;

          i {
            font-size: $font-size-20;
          }
        }
      }
    }

    &__parent {
      margin-bottom: .25rem;
      font-size: $font-size-14;
      font-weight: $font-weight-light;
      color: var(--color-midnight-lighter);
    }

    &__title {
      display: inline-block;
      font-size: $font-size-26;
      font-weight: $font-weight-bold;
      margin-bottom: 1rem;

      > * {
        vertical-align: middle;
      }

      > :not(:last-child) {
        margin-right: .5rem;
      }
    }

    &__description {
      margin-bottom: 2rem;
      font-size: $font-size-14;
      font-weight: $font-weight-light;
      color: var(--color-midnight-lighter);
    }

    &__tags {
      display: flex;
      align-items: center;
      flex-wrap: wrap;

      > * {
        margin-top: 0.25rem;

        &:not(:last-child) {
          margin-right: 0.275rem;
        }
      }
    }

    &__infos {
      display: flex;
      align-items: center;
      justify-content: space-between;

      &__info {
        display: flex;
        flex-direction: column;
        width: 45%;

        &__name {
          display: inline-flex;
          font-size: $font-size-14;
          color: var(--color-grey-dark);
          text-transform: uppercase;
          margin-bottom: .25rem;

          &__icon {
            display: inline-flex;
            font-size: $font-size-18;
            margin-right: .25rem;
          }
        }

        &__value {
          font-size: $font-size-36;
          font-weight: $font-weight-semi-bold;
          white-space: nowrap;
        }
      }
    }

    &__sub-infos {
      display: flex;
      flex-direction: column;
      margin-top: 1rem;

      &__info {
        display: inline-flex;
        margin: 0;
        font-size: $font-size-14;
        font-weight: $font-weight-light;
        color: var(--color-midnight-light);

        &:not(:last-child) {
          margin-bottom: .75rem;
        }

        i {
          font-size: $font-size-18;
          margin-right: .75rem;
          color: var(--color-grey-dark);
        }
      }
    }
  }

  .details__more {
    width: 100%;

    &__section {
      &__title {
        display: inline-flex;
        text-transform: initial;
        margin: 1rem auto 0;

        &__icon {
          display: inline-flex;
          transform: rotate(90deg);
          text-shadow: var(--color-grey-light) 0 0 2px;
        }

        :not(:first-child) {
          margin-left: .5rem;
        }
      }

      &__text {
        font-weight: $font-weight-semi-bold;
      }

      &__content {
        width: 100%;
        display: flex;
        flex-direction: column;
        margin-top: 2rem;

        &__title {
          font-weight: $font-weight-semi-bold;
          margin: 0 0 .75rem;

          &:not(:first-child) {
            margin-top: 2rem;
          }
        }

        &__placeholder {
          text-align: center;
          color: var(--color-midnight-light);
          font-size: $font-size-16;
        }
      }

      &__platforms {
        display: flex;
        flex-direction: column;

        &__item {
          display: flex;
          align-items: center;
          justify-content: space-between;
          border: 1px solid var(--color-grey);
          border-radius: var(--border-radius-md);
          background-color: var(--color-white);
          padding: 1rem 2rem;
          min-height: 5rem;
          width: 100%;

          &:not(:last-child) {
            margin-bottom: .5rem;
          }

          &__platform {
            display: flex;
            align-items: center;
            flex-wrap: nowrap;
            overflow: hidden;

            &__logo {
              display: flex;
              align-items: center;
              justify-content: center;
              height: 2.5rem;
              width: 8rem;

              img {
                max-height: 100%;
                max-width: 100%;
              }
            }

            &__name {
              margin-left: 3rem;
              font-size: $font-size-16;
              font-weight: $font-weight-bold;
              color: var(--color-midnight-light);
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
            }
          }

          &__value {
            width: 20%;
            min-width: 10rem;

            &__action {
              width: 100%;
              text-align: center;
            }
          }
        }
      }

      &__keywords {
        width: 100%;
        max-height: 20rem;
        display: flex;
        flex-wrap: wrap;
        overflow: auto;
        padding: 1rem;
        background-color: var(--color-grey-light);
        border: 1px solid var(--color-grey);
        border-radius: var(--border-radius-md);
        gap: var(--spacing-xs);
      }

      &__url-examples {
        width: 100%;
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
        gap: var(--spacing-sm);
        overflow: auto;
        padding: var(--spacing-sm);
        background-color: var(--color-grey-light);
        border: 1px solid var(--color-grey);
        border-radius: var(--border-radius-md);

        &__item {
          display: flex;
          align-items: center;
          gap: var(--spacing-sm);

          a {
            color: var(--color-midnight-light);
            word-break: break-word;

            &:hover {
              text-decoration: none;
            }
          }

          i {
            color: var(--color-green);
          }
        }
      }

      &__print-volumes {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-evenly;

        &__item {
          display: flex;
          flex-direction: row;
          align-items: center;
          margin: 1rem 2rem;

          &__flag {
            display: flex;
            flex-direction: column;
            font-size: $font-size-14;
            color: var(--color-midnight-light);
            text-align: center;

            img {
              height: 3rem;
              box-shadow: $box-shadow;
              border-radius: var(--border-radius-md);
              margin-bottom: .5rem;
            }
          }

          &__value {
            display: flex;
            flex-direction: column;
            margin-left: 1rem;
            margin-bottom: 1.5rem;
            font-size: $font-size-14;
            color: var(--color-midnight-light);
            font-weight: $font-weight-light;

            span {
              font-weight: $font-weight-bold;
            }
          }
        }
      }

      &__cards {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        gap: .5rem;

        &__item {
          padding: 0 var(--spacing-md);
          border: 1px solid var(--color-grey);
          border-radius: var(--border-radius-md);
          display: flex;
          align-items: center;
          justify-content: center;
          width: 15rem;
          height: 6rem;

          img {
            max-height: 100%;
            max-width: 100%;
          }
        }
      }
    }
  }
}
