.relevancy-setting {
  display: flex;
  justify-content: center;
  align-items: flex-end;
  width: 100%;

  .relevancy-setting-label {
    color: var(--color-midnight-light);
    margin-right: .5rem;
    font-size: $font-size-16;
    font-weight: $font-weight-bold;
  }

  .relevancy-setting-content {
    position: relative;
    width: 100%;

    .relevancy-setting-bars {
      height: 12rem;
      z-index: 1;
      display: flex;
      justify-content: space-between;
      align-items: flex-end;

      .relevancy-setting-item {
        display: flex;
        justify-content: center;
        width: 15%;
        background: var(--color-grey);
        cursor: pointer;

        &::after {
          content : "";
          position: absolute;
          z-index: -1;
          bottom: 0;
          right: unset;
          width: 1.5rem;
          height: 1.5rem;
          border-radius: var(--border-radius-xl);
          background: var(--color-grey);
          cursor: pointer;
        }

        &.active {
          background: var(--color-midnight-light);

          &::after {
            background: var(--color-midnight-light);
          }
        }
      }
    }

    .relevancy-setting-slide {
      position: relative;
      margin: 2rem 5% .4rem;
      height: 0.75rem;
      background: var(--color-grey);
      border-radius: var(--border-radius-xs);
      cursor: pointer;

      input[type=range].range-slider-input {
        position: absolute;
        left: 0;
        right: 0;
        width: 100%;
        height: .75rem;
        appearance: none;
        background: var(--color-midnight-light);
        z-index: 2;
        cursor: pointer;
        border-radius: var(--border-radius-xs);

        &.input-value-500 {
          background: var(--color-midnight-light);
        }

        &.input-value-600 {
          background: -webkit-linear-gradient(left, var(--color-grey) 0%, var(--color-grey) 25%, var(--color-midnight-light) 25%, var(--color-midnight-light) 75%);
        }

        &.input-value-700 {
          background: -webkit-linear-gradient(left, var(--color-grey) 0%, var(--color-grey) 50%, var(--color-midnight-light) 50%);
        }

        &.input-value-800 {
          background: -webkit-linear-gradient(left, var(--color-grey) 0%, var(--color-grey) 75%, var(--color-midnight-light) 25%);
        }

        &.input-value-900 {
          background: var(--color-grey);
        }

        &::-webkit-slider-thumb {
          -webkit-appearance: none;
          appearance: none;
          width: 1.5rem;
          height: 1.5rem;
          border-radius: var(--border-radius-xl);
          background-color: var(--color-grey-light);
          border: 2px solid var(--color-midnight-light);
        }

        /* FF*/
        &::-moz-range-thumb {
          appearance: none;
          width: 1.5rem;
          height: 1.5rem;
          border-radius: var(--border-radius-xl);
          background-color: var(--color-grey-light);
          border: 2px solid var(--color-midnight-light);
        }

        &::-moz-range-progress {
          background-color: var(--color-grey);
          height: .8rem;
          border-radius: var(--border-radius-xs);
        }

        /* IE*/
        &::-ms-thumb {
          appearance: none;
          width: 1.5rem;
          height: 1.5rem;
          border-radius: var(--border-radius-xl);
          background-color: var(--color-grey-light);
          border: 2px solid var(--color-midnight-light);
        }

        &::-ms-fill-lower {
          background-color: var(--color-grey);
          height: .8rem;
          border-radius: var(--border-radius-xs);
        }
      }
    }
  }
}
