.grid-cards {
  display: grid;
  grid-gap: var(--spacing-lg);
  grid-template-columns: repeat(auto-fill, minmax(16rem, 1fr));

  @media #{$screen-s} {
    grid-template-columns: repeat(auto-fill, minmax(10rem, 1fr));
  }
}

.grid-card {
  position: relative;
  height: 14rem;
  text-decoration: none;

  &__menu {
    position: absolute;
    top: var(--spacing-sm);
    right: var(--spacing-sm);
    z-index: 1;
  }

  &__icon {
    font-size: $font-size-70 !important;
    color: var(--color-midnight-light);
  }

  &__logo {
    height: 5rem;

    img {
      max-width: 100%;
      max-height: 100%;
    }
  }

  &__name {
    max-height: 3rem;
    overflow: hidden;
    line-height: 1.5;
    font-size: $font-size-16;
    font-weight: $font-weight-semi-bold;
    color: var(--color-midnight);
    text-align: center;
    word-break: break-word;
  }

  &__input {
    padding: 0.5rem 1rem;
  }

  &:hover,
  &.active {
    box-shadow: $box-shadow-green;

    .grid-card {
      &__icon,
      &__name {
        color: var(--color-green);
      }
    }
  }

  @media #{$screen-s} {
    padding: 1.5rem 1rem!important;
    height: auto;

    .grid-card {
      &__icon {
        font-size: $font-size-36;
      }

      &__name {
        font-size: $font-size-14;
      }
    }
  }
}
