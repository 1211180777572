.taxonomy-element {
  display: flex;
  align-items: center;
  background-color: var(--color-white);
  border-radius: var(--border-radius-md);
  border: 1px solid var(--color-grey);
  border-left: 0;
  margin-bottom: 0.5rem;
  padding-left: 10px;
  position: relative;

  @media #{$screen-s}, #{$screen-m} {
    margin-right: 0;
  }

  .element__border {
    position: absolute;
    border-radius: var(--border-radius-md) 0 0 var(--border-radius-md);
    background: var(--color-grey);
    width: 10px;
    top: 0;
    left: 0;
    bottom: 0;
  }

  .element__main {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 100%;
    padding: 0.75rem 1rem;
    color: var(--color-midnight-light);
    border-right: 1px solid;
    border-image-slice: 1;
    border-image-source: linear-gradient(
      to top,
      transparent 0 4%,
      var(--color-grey) 4% 96%,
      transparent 96% 100%
    );
    text-decoration: none;

    &__column {
      display: flex;
      flex-direction: column;
      align-items: center;
    }

    &__row {
      display: flex;
      align-items: flex-start;
      justify-content: space-between;
      width: 100%;

      &:not(:last-child) {
        margin-bottom: 0.5rem;
      }
    }

    &__vertical {
      font-size: $font-size-14;
      font-weight: $font-weight-light;
      color: var(--color-midnight-lighter);
    }

    &__name {
      display: inline-block;
      font-size: $font-size-16;
      font-weight: $font-weight-semi-bold;

      > * {
        vertical-align: middle;
      }

      > :not(:last-child) {
        margin-right: .5rem;
      }

      &__icon i {
        font-size: $font-size-18;
        color: var(--color-grey-dark);
      }
    }

    &__infos {
      display: flex;
      align-items: flex-end;

      .element__main__info {
        display: flex;
        align-items: center;
        margin: 0 0 0 1rem;

        &__icon {
          margin-right: 0.25rem;
          font-size: $font-size-16;
          color: var(--color-grey-dark);
        }

        &__value {
          font-size: $font-size-12;
          font-weight: $font-weight-bold;
          color: var(--color-midnight-light);
          white-space: nowrap;

          &--highlighted {
            color: var(--color-green);
          }
        }
      }

      @media #{$screen-s}, #{$screen-m} {
        flex-direction: column;

        .element__main__info:not(:first-child) {
          margin-top: 0.5rem;
        }
      }
    }

    &__tags {
      display: flex;
      align-items: center;
      flex-wrap: wrap;

      > * {
        margin-top: 0.25rem;

        &:not(:last-child) {
          margin-right: 0.275rem;
        }
      }
    }
  }

  .element__actions {
    display: flex;
    align-items: center;
    justify-content: center;
    color: var(--color-midnight-light);
    padding: 0.75rem;

    @media #{$screen-s}, #{$screen-m} {
      flex-direction: column;
    }

    &__item {
      display: inline-flex;
      margin: 0.25rem;

      i {
        font-size: $font-size-18;
      }
    }
  }

  &.taxonomy-tree__item__block {
    .element__actions {
      width: 8rem;

      @media #{$screen-s}, #{$screen-m} {
        width: 4rem;
      }
    }
  }

  &.taxonomy-list__item__block {
    .element__actions {
      width: 16rem;

      @media #{$screen-s}, #{$screen-m} {
        width: 4rem;
      }
    }
  }
}
