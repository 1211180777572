.data-selection {
  padding: 0 var(--spacing-lg);

  &__search {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin: 0 auto 3rem;
    max-width: 25rem;
  }

  &__themes {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    margin-bottom: var(--spacing-sm);

    .data-selection__theme {
      padding: 0 var(--spacing-md) var(--spacing-lg);
      margin: 0 var(--spacing-md) 0 0;
      font-size: $font-size-18;
      font-weight: $font-weight-semi-bold;
      color: var(--color-midnight-dark);
      cursor: pointer;

      &:last-child {
        margin: 0;
      }

      &:hover,
      &--active {
        text-decoration: var(--color-green) solid underline;
        text-underline-offset: var(--spacing-sm);
        text-decoration-thickness: .25rem;
      }
    }

    @media #{$screen-s}, #{$screen-m} {
      width: 100%;

      .data-selection__theme {
        margin: 0;
      }
    }
  }

  &__items {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: auto;
    max-width: 80rem;

    .data-selection__row {
      display: flex;
      flex-direction: row;
      width: 100%;

      > :nth-child(1) {
        width: 30%;
      }

      > :nth-child(2) {
        width: 70%;
      }

      &:nth-child(even) {
        > :nth-child(1) {
          width: 70%;
        }

        > :nth-child(2) {
          width: 30%;
        }
      }

      @media #{$screen-s}, #{$screen-m} {
        flex-direction: column;

        > :nth-child(N) {
          width: 100% !important;
          margin-left: 0;
          margin-right: 0;
        }
      }
    }

    .data-selection__item {
      position: relative;
      margin: var(--spacing-xs);
      border-radius: var(--border-radius-xl);
      padding-bottom: 25%;
      background-color: var(--color-grey);
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
      cursor: pointer;
      overflow: hidden;

      &--pinned {
        width: 100% !important;
      }

      &:hover {
        &:before {
          content: '';
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          background-color: var(--color-grey-light);
          opacity: .6;
        }

        .data-selection__item__filter {
          display: flex;
        }
      }

      &__title {
        position: absolute;
        left: var(--spacing-lg);
        right: var(--spacing-lg);
        bottom: var(--spacing-sm);
        font-size: $font-size-36;
        font-weight: $font-weight-bold;
        color: var(--color-grey-light);
        text-shadow: var(--color-midnight-dark) 0.1em 0.1em 0.15em;
      }

      &__pinned-tag {
        display: flex;
        align-items: center;
        position: absolute;
        left: var(--spacing-lg);
        top: var(--spacing-sm);
        padding: var(--spacing-xs) var(--spacing-md);
        font-size: $font-size-16;
        font-weight: $font-weight-semi-bold;
        border-radius: var(--border-radius-xl);
        box-shadow: $box-shadow;
        background-color: var(--color-green);
        color: var(--color-grey-light);
      }

      &__tag {
        position: absolute;
        right: var(--spacing-lg);
        top: var(--spacing-sm);
        padding: var(--spacing-xs) var(--spacing-md);
        font-size: $font-size-16;
        font-weight: $font-weight-semi-bold;
        border-radius: var(--border-radius-xl);
        box-shadow: $box-shadow;
        background-color: var(--color-grey-light);
        color: var(--color-midnight);
      }

      &__filter {
        display: none;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        align-items: center;
        justify-content: center;

        &__button {
          display: flex;
          align-items: center;
          padding: var(--spacing-xs) var(--spacing-md);
          font-size: $font-size-16;
          font-weight: $font-weight-semi-bold;
          border-radius: var(--border-radius-xl);
          box-shadow: $box-shadow;
          background-color: var(--color-grey-light);
          color: var(--color-midnight);

          &__icon {
            margin-left: var(--spacing-sm);
            color: var(--color-green);
          }
        }
      }

      @media #{$screen-s}, #{$screen-m} {
        &__title {
          left: var(--spacing-md);
          font-size: $font-size-28;
        }

        &__pinned-tag {
          left: var(--spacing-sm);
          padding: var(--spacing-xs);

          &__label {
            display: none;
          }
        }

        &__tag {
          right: var(--spacing-md);
          padding: var(--spacing-xs) var(--spacing-sm);
        }
      }
    }
  }
}

.data-selection-details {
  & &__header {
    position: relative;
    padding-bottom: 25%;
    background-color: var(--color-grey);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;

    .data-selection__breadcrumb {
      position: absolute;
      left: var(--spacing-xl);
      bottom: var(--spacing-lg);
      display: flex;
      flex-wrap: wrap;
      align-items: center;

      &-item {
        display: flex;
        align-items: center;
        margin-right: var(--spacing-xs);

        &__chip {
          margin-right: var(--spacing-xs);
          height: 2.5rem;

          svg {
            height: 2.5rem;
            fill: currentColor;
          }
        }

        &__icon,
        &__link,
        &__title {
          font-size: $font-size-26;
          font-weight: $font-weight-bold;
          letter-spacing: 0.4pt;
        }

        &__link {
          color: var(--color-green);
          cursor: pointer;
          text-decoration: none;

          &:hover {
            text-decoration: underline;
          }
        }

        &__icon,
        &__title {
          color: var(--color-grey-light);
        }
      }

      @media #{$screen-s}, #{$screen-m} {
        left: var(--spacing-lg);

        &-item {
          &__icon,
          &__link,
          &__title {
            font-size: $font-size-20;
          }
        }
      }
    }
  }

  & &__items {
    padding: var(--spacing-xl);
    margin: auto;

    @media #{$screen-s}, #{$screen-m} {
      padding: var(--spacing-lg);
    }
  }
}
