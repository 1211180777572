.chip {
  display: inline-flex;
  align-items: center;
  gap: var(--spacing-xs);
  border-radius: var(--border-radius-xl);
  color: var(--color-grey-light);
  background-color: var(--color-midnight-light);
  border: 1px solid var(--color-midnight-light);
  padding: .215rem 1.075rem;

  & > i {
    font-size: $font-size-20;
    cursor: pointer;
  }

  &--outlined {
    color: var(--color-midnight-light);
    background: none;
  }
}

.chip-input {
  display: inline-flex;
  align-items: center;

  & > input {
    width: auto;
    padding: .215rem 1.075rem;
    margin-right: var(--spacing-xs);
    border: 1px solid var(--color-white);
  }

  & > i {
    font-size: $font-size-14;
    color: var(--color-midnight-light);
    background-color: var(--color-white);
    border-radius: var(--border-radius-lg);
    padding: .25rem;
    gap: var(--spacing-xs);
    cursor: pointer;

    &:not(:first-child) {
      margin-left: .25rem;
    }

    &:hover {
      box-shadow: $box-shadow;
    }
  }
}
