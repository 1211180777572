.modal-targeting-segment {
  &__favorite-button {
    align-self: flex-end;
    margin-bottom: var(--spacing-xs);
  }

  &__selection {
    display: flex;
    align-items: center;
    gap: var(--spacing-xs);
    color: var(--color-midnight-light);
    padding: 0 var(--spacing-xs);

    & i {
      font-size: $font-size-18;
    }
  }

  &__results {
    width: 100%;
    height: 12rem;
    border: 1px solid var(--color-grey);
    border-radius: var(--border-radius-md);
    padding: 1rem;
    overflow-y: scroll;

    &::-webkit-scrollbar {
      width: .357rem;
    }

    &::-webkit-scrollbar-thumb {
      border: 0 solid transparent;
      border-top-left-radius: calc(var(--border-radius-md) / 4);
      border-bottom-left-radius: calc(var(--border-radius-md) / 4);
      -webkit-box-shadow: inset 0 0 0 3px var(--color-midnight);
      -moz-box-shadow: inset 0 0 0 3px var(--color-midnight);
      box-shadow: inset 0 0 0 3px var(--color-midnight);
    }

    &::-webkit-scrollbar-track {
      margin-top: var(--border-radius-md);
      margin-bottom: var(--border-radius-md);
    }

    &__placeholder {
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      color: var(--color-grey-dark);
    }
  }
}

.modal-platform-selection {
  position: relative;

  & &__tag-platform {
    position: absolute;
    left: var(--spacing-sm);
    background-color: var(--color-green);
    border-color: var(--color-green);
    z-index: 1;
  }

  & &__chips {
    width: 100%;
    display: inline-flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: var(--spacing-xs);
    border-radius: var(--border-radius-md);
    background-color: var(--color-grey-light);
    padding: 1.5rem 1rem 1rem;
    margin-top: 1rem;
  }

  & &__btn-delete {
    & > i {
      color: var(--color-white);
      font-size: $font-size-14;
    }
  }
}

.modal-deal {
  &-details {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    border: 1px solid var(--color-grey);
    border-radius: var(--border-radius-md);
    padding: 1.5rem;

    &__parent {
      display: block;
      color: var(--color-grey-dark);
      font-size: $font-size-14;
      line-height: 1.5;
      margin-bottom: .5rem;
    }

    &__title {
      display: inline-block;
      font-weight: $font-weight-bold;
      color: var(--color-midnight-light);
      margin-bottom: .5rem;
      line-height: 1.5;
      font-size: $font-size-18;
    }

    &__tags {
      display: flex;
      align-items: center;
      flex-wrap: wrap;

      > * {
        margin-top: 0.25rem;

        &:not(:last-child) {
          margin-right: 0.275rem;
        }
      }
    }
  }
}
