.share-favorites-folder {
  display: inline-flex;
  align-items: center;
  gap: var(--spacing-xs);
  height: 3rem;

  &__toggle-public {
      display: inline-flex;
      align-items: center;
      gap: var(--spacing-xs);
      font-weight: $font-weight-bold;
      color: var(--color-midnight-light);
  }
}

.favorites-folder-public {
  &__header {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: var(--spacing-xs);
    background-color: var(--color-ocean-lighter);
    border-color: var(--color-ocean-light);
    color: var(--color-midnight-light);
  
    &__title {
      display: flex;
      gap: var(--spacing-xs);
      align-items: center;
      color: var(--color-ocean);
  
      & > i {
        font-size: $font-size-36;
      }
  
      & > h1 {
        color: var(--color-ocean);
      }
    }
  }
}

.favorites-folder-not-public {
  text-align: center;

  & > h1 {
    color: var(--color-ocean);
  }

  & > p {
    margin: calc(var(--spacing-md) * 2) 0;
  }

  &__buttons {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: var(--spacing-sm);

    @media #{$screen-s}, #{$screen-m} {
      flex-direction: column;
    }

    &__button {
      width: 100%;
      max-width: 18rem;
      display: flex;
      align-items: center;
      justify-content: space-between;
  
      &:hover {
        cursor: pointer;
        border-color: var(--color-green);
        box-shadow: var(--color-green) 0 0 0 1px;
      }
  
      &__title {
        display: flex;
        align-items: center;
        gap: var(--spacing-xs);
        color: var(--color-midnight-light);
        font-size: $font-size-18;
        font-weight: $font-weight-semi-bold;
      }
  
      & i:last-child {
        color: var(--color-green);
      }
    }
  }
}
