.home {
  & &__audiences {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: center;

    .audiences__loader {
      margin: 10rem 0;
    }

    .audiences__row {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      flex-wrap: wrap;
      width: 100%;

      &:not(:last-child) {
        margin: 0 0 2rem 0;
        padding: 0 0 2rem 0;
        border-bottom: 1px solid var(--color-grey);
      }

      @media #{$screen-s}, #{$screen-m} {
        flex-direction: column;
      }
    }

    .audiences__item {
      display: block;
      width: 48%;
      color: var(--color-midnight-light);

      &:first-child {
        padding: 0 2.5% 0 0;
        margin: 0 2.5% 0 0;
        border-right: 1px solid var(--color-grey);
      }

      &__column {
        display: flex;
        flex-direction: column;
        align-items: center;
      }

      &__row {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;

        &:not(:last-child) {
          margin-bottom: 0.5rem;
        }
      }

      &__vertical {
        font-size: $font-size-14;
        font-weight: $font-weight-light;
        color: var(--color-midnight-lighter);
      }

      &__name {
        display: inline-block;
        font-size: $font-size-16;
        font-weight: $font-weight-semi-bold;
        text-decoration: none;

        > * {
          vertical-align: middle;
        }

        > :not(:last-child) {
          margin-right: .5rem;
        }

        &:hover {
          color: var(--color-green);
        }

        &__icon i {
          font-size: $font-size-18;
          color: var(--color-grey-dark);
        }
      }

      &__infos {
        display: flex;
        align-items: flex-end;

        .audiences__item__info {
          display: flex;
          align-items: center;
          margin: 0 0 0 1rem;

          &__icon {
            margin-right: 0.25rem;
            font-size: $font-size-16;
            color: var(--color-grey-dark);
          }

          &__value {
            font-size: $font-size-12;
            font-weight: $font-weight-bold;
            color: var(--color-midnight-light);
            white-space: nowrap;
          }
        }
      }

      &__tags {
        display: flex;
        align-items: center;
        flex-wrap: wrap;

        > * {
          margin-top: 0.25rem;

          &:not(:last-child) {
            margin-right: 0.275rem;
          }
        }
      }

      @media #{$screen-s}, #{$screen-m} {
        width: 100%;

        &:not(:last-child) {
          margin: 0 0 2rem 0;
          padding: 0 0 2rem 0;
          border: 0;
          border-bottom: 1px solid var(--color-grey);
        }
      }
    }
  }
}
