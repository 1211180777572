.partners {
  &.partners-midnight {
    background-color: var(--color-midnight);
    background: radial-gradient(closest-side, var(--color-midnight-light) 0%, var(--color-midnight) 90%);

    h2, h3, p {
      color: var(--color-grey);
    }
  }

  .partners-table-of-contents {
    display: flex;
    flex-direction: column;
    border-left: 2px solid var(--color-midnight-light);

    .table-of-contents-items {
      display: flex;
      flex-direction: column;

      .table-of-contents-item {
        padding: 1rem 2.5rem;
        font-size: $font-size-16;
        font-weight: $font-weight-semi-bold;
        color: var(--color-midnight-light);
        text-decoration: none;
        transition: all 0.1s;
        text-transform: uppercase;
        border-left: 5px solid transparent;

        &:hover {
          color: var(--color-green);
          border-color: var(--color-green);
        }
      }
    }
  }

  .partners-section {
    margin: 0 auto;
    max-width: 125rem;
    padding: 4rem;

    @media #{$screen-s}, #{$screen-m}, #{$screen-l} {
      padding: 2rem;
    }

    .partners-section-title {
      text-align: center;
      font-size: $font-size-26 !important;
    }

    .partners-section-info {
      margin: 6rem 0;

      p {
        text-align: justify;
      }

      .partners-block-with-text {
        display: flex;
        align-items: center;
        flex-direction: row;

        @media #{$screen-s}, #{$screen-m} {
          flex-direction: column;
          align-items: stretch;
        }

        & > :first-child {
          width: 20rem;

          @media #{$screen-s}, #{$screen-m} {
            width: auto;
          }
        }

        &:not(:last-child) {
          margin-bottom: 2rem;
        }

        .partners-block-text {
          margin: 0 0 0 1.5rem;
          max-width: 60%;
          line-height: 1.5;
          font-size: $font-size-14;
          color: var(--color-midnight-light);
          text-align: justify;

          @media #{$screen-s}, #{$screen-m} {
            max-width: 100%;
            margin: 1.5rem 0 0;
          }
        }
      }
    }
  }
}
