.keywords-section-container {
  color: var(--color-midnight-light);

  .keywords-section-title {
    display: flex;
    align-items: center;
    margin-bottom: 1rem;
    text-transform: uppercase;

    &:not(:first-child) {
      margin-top: 2rem;
    }
  }

  .keywords-section-subtitle {
    display: flex;
    justify-content: center;
    margin: 2rem 0 1rem;
    position: relative;

    > span {
      padding: 0 1rem;
      background: var(--color-white);
      font-size: $font-size-14;
      font-weight: $font-weight-semi-bold;
      color: var(--color-midnight-light);
    }

    &:after {
      content: "";
      position: absolute;
      top: 50%;
      width: 100%;
      height: 1px;
      background: var(--color-grey);
      z-index: -1;
    }
  }

  .keywords-section-text {
    display: flex;
    align-self: center;
    justify-content: center;
    width: 100%;
    font-size: $font-size-18;
    font-weight: $font-weight-regular;
    color: var(--color-grey-dark);
    text-align: center;
  }

  .keywords-section-button {
    position: absolute;
    top: -.5rem;
    right: -.5rem;
    width: 2.5rem;
    height: 2.5rem;
    display: flex;
    align-items: center;
    justify-content: center;
    background: var(--color-white);
    border-radius: 50%;
    box-shadow: $box-shadow;
    cursor: pointer;

    &__icon {
      &:hover > i {
        color: var(--color-red);
      }
    }
  }

  .keywords-section-tooltip {
    margin: 0 .25rem;

    i {
      font-size: $font-size-18;
    }
  }
}

.keywords-edition {
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  .keywords-edition-search {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    position: relative;

    .keywords-search-mode {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      flex-wrap: wrap;
      margin: 1rem 0;
      font-size: $font-size-12;

      .keywords-search-mode-title,
      .keywords-search-mode-field {
        margin-bottom: .5rem;
      }

      .keywords-search-mode-title {
        display: flex;
        align-items: center;
        font-weight: $font-weight-bold;
      }

      .keywords-search-mode-field {
        display: flex;
        align-items: center;
        margin-left: .25rem;

        label {
          margin: 0;
          font-weight: $font-weight-regular;
          cursor: pointer;
        }

        input {
          margin: 0 .5rem;
          width: 3rem;
        }
      }
    }

    .keywords-search-suggestions {
      margin: .5rem 0;
      height: 15.5rem;
      display: flex;
      justify-content: space-evenly;
      align-items: center;
      flex-wrap: wrap;
      overflow: auto;
      gap: var(--spacing-xs);
    }
  }

  .keywords-edition-import {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 5.5rem;

    > :not(:first-child) {
      margin-top: .5rem;
    }

    .keywords-settings-import-message {
      margin-top: 1rem;
      color: var(--color-green);

      &.error {
        color: var(--color-red);
      }
    }
  }
}

.keywords-selection {
  height: 45rem;
  position: relative;
  display: flex;
  align-items: flex-start;

  .keywords-selection-content {
    display: flex;
    flex-wrap: wrap;
    max-height: 100%;
    overflow: auto;
    gap: var(--spacing-xs);
  }
}

.keywords-urls {
  display: flex;
  justify-content: center;
  height: 17.5rem;

  &.keywords-urls-full {
    height: 45rem;
  }

  .keywords-urls-content {
    display: flex;
    flex-direction: column;
    gap: var(--spacing-sm);
    height: 100%;
    width: 100%;
    padding-right: 1rem;
    overflow: auto;

    .keywords-url {
      display: flex;
      gap: var(--spacing-sm);

      a {
        word-break: break-all;

        &:hover {
          text-decoration: none;
        }
      }

      i {
        color: var(--color-green);
      }
    }
  }
}

.keywords-suggested-categories {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 23rem;

  .suggested-categories-content {
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;
    padding-right: 1rem;
    overflow: auto;

    .suggested-category {
      position: relative;
      display: flex;
      flex-direction: row;
      align-items: flex-start;
      justify-content: space-between;

      &:not(:last-child) {
        margin-bottom: .75rem;
      }

      .suggested-category-infos {
        display: flex;
        flex-direction: column;

        .suggested-category-parent {
          line-height: 1.5;
          color: var(--color-grey-dark);
          text-decoration: none;
          cursor: pointer;

          &:hover {
            text-decoration: underline;
          }
        }

        .suggested-category-name {
          line-height: 1.5;
          font-size: $font-size-16;
          font-weight: $font-weight-semi-bold;
          color: var(--color-midnight);
          cursor: pointer;

          &:hover {
            color: var(--color-green);
          }
        }
      }

      .suggested-category-volume {
        display: flex;
        align-items: center;

        .suggested-category-volume-icon {
          color: var(--color-green);
        }

        .suggested-category-volume-value {
          display: flex;
          align-items: center;
          justify-content: center;
          margin-left: .5rem;
          width: 5rem;
          height: 2rem;
          background-color: var(--color-grey);
          border-radius: var(--border-radius-xl);
          font-size: $font-size-14;
        }
      }
    }
  }
}
