.home {
  & &__platforms {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
    gap: var(--spacing-lg);

    .platform-logo {
      display: flex;
      align-items: center;
      justify-content: center;
      max-width: 7rem;
      max-height: 3rem;
      height: auto;

      img {
        max-width: 100%;
        max-height: 100%;
      }
    }
  }
}
