.taxonomy-filters {
  display: flex;
  flex-direction: column;
  padding-bottom: 1rem;
  border-bottom: 1px solid var(--color-grey);

  & &__title {
    display: inline-flex;
    align-items: center;

    &__icon {
      margin-right: 0.25rem;
      font-size: $font-size-24;
    }
  }

  & &__content {
    display: flex;
    align-items: flex-start;
    margin-top: 1rem;

    &--hidden {
      display: none;
    }

    @media #{$screen-s}, #{$screen-m} {
      flex-direction: column;
    }

    .filter-group {
      padding: 1rem;
      background-color: var(--color-ocean-lighter);
      border-radius: var(--border-radius-md);
      display: flex;
      flex-direction: column;
      gap: var(--spacing-xs);
      width: 100%;
      margin-bottom: 0.5rem;

      &:not(:last-child) {
        margin-right: 0.5rem;
      }

      &__items {
        display: flex;

        .filter-item {
          display: flex;
          align-items: center;
          justify-content: center;
          background-color: var(--color-white);
          border-radius: var(--border-radius-md);
          color: var(--color-midnight-light);
          border: 1px solid var(--color-ocean-light);
          width: 33%;
          position: relative;

          &:not(:last-child) {
            width: calc(33% - 0.5rem);
            margin-right: 0.5rem;
          }

          &:hover,
          &--selected {
            border: 2px solid var(--color-ocean);
          }

          &--disabled {
            color: var(--color-grey-dark)!important;

            &:hover {
              border: 1px solid var(--color-ocean-light);
            }
          }

          &--selected {
            box-shadow: inset 0 0 8px var(--color-ocean);

            &.filter-item--has-subitems:after {
              font-family: $font-family-icon;
              content: "\e5cc";
              transform: rotate(90deg);
              color: var(--color-midnight-light);
              font-size: $font-size-24;
              position: absolute;
              top: 105%;
            }
          }

          &__label {
            padding: 0.75rem 1rem;
            display: flex;
            align-items: center;
            flex-direction: column;
            cursor: pointer;
            width: 100%;

            > span {
              text-align: center;
              word-break: break-word;
              font-weight: $font-weight-bold;
            }
          }

          &__icon {
            margin-bottom: 0.5rem;
            font-size: $font-size-18;

            @media #{$screen-s} {
              display: none;
            }
          }

          input[type="radio"] {
            display: none;
          }
        }
      }

      &__subitems {
        display: flex;
        flex-direction: column;
        margin-top: 2rem;
        background-color: var(--color-white);
        border-radius: var(--border-radius-md);
        border: 1px solid var(--color-ocean-light);
        position: relative;
        color: var(--color-midnight-light);
        font-weight: $font-weight-bold;

        &--hidden {
          display: none;
        }

        .filter-subitem {
          display: flex;
          align-items: center;
          justify-content: space-between;
          padding: 1rem;
          min-height: 4.5rem;

          &:not(:last-child) {
            border-bottom: 1px solid var(--color-ocean-light);
          }

          &__label {
            margin-right: 1rem;

            &--with-icon {
              display: flex;
              align-items: center;
              gap: .5rem;
            }
          }

          &__content {
            width: 50%;

            .input-select {
              width: 100%;

              & select {
                border: 1px solid var(--color-grey);
                border-radius: var(--border-radius-md);
                font-weight: $font-weight-semi-bold;
                font-size: 1rem;
              }
            }
          }

          &__full-content {
            width: 100%;

            .filter-button {
              display: inline-block;
              border: 1px solid var(--color-grey);
              font-weight: $font-weight-semi-bold;
              border-radius: var(--border-radius-xl);
              padding: .3rem 1rem;
              margin-top: .4rem;
              margin-right: .25rem;
              cursor: pointer;

              &:hover,
              &--active {
                background-color: var(--color-midnight-light);
                color: var(--color-grey-light);
              }
            }
          }

          @media #{$screen-l} {
            flex-direction: column;

            &__label {
              margin-right: 0;
              margin-bottom: .5rem;
            }

            &__content {
              width: 100%;
            }
          }
        }
      }
    }
  }
}
