.tag {
  .tag__logo {
    svg {
      height: .8rem;
    }
  }

  &.tag-segment-row {
    width: 100% !important;
    justify-content: space-between;
    color: var(--color-midnight-light);
    border-color: var(--color-grey-light);
    background: var(--color-grey-light);
    padding: .6rem 1rem;
    margin-bottom: .5rem;

    & > .tag-segment-row__info {
      align-items: center;
      display: inline-flex;
      gap: var(--spacing-xs);
    }
  }
}

.tag-user-segment-type {
  display: flex;

  & .tag:first-child {
    padding-right: 2rem;
  }

  & .tag:last-child {
    left: -1.5rem;
    margin-right: -1.5rem;
  }
}

.tag-kantar-media,
.tag-euid {
  margin-left: var(--spacing-xs);
  
  & > img {
    height: .7rem;
  }
}
