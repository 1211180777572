.home {
  & &__partners {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;

    .partner-logo {
      display: flex;
      align-items: center;
      max-width: 5rem;
      height: auto;
      margin: 1rem 1.5rem;

      img {
        width: 100%;
      }
    }
  }
}
