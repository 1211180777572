.activation-card {
  margin: var(--spacing-sm);

  .activation-card__title > span {
    color: var(--color-green);
  }

  .activation-card__terms {
    margin-left: var(--spacing-sm);
    color: var(--color-midnight);

    @media #{$screen-s} {
      margin-left: 0;
      margin-top: var(--spacing-xs);
    }
  }
}

.activation-section {
  margin: 0 auto;
  max-width: 80rem;

  svg {
    max-height: 25rem;
    width: 100%;
  }
}