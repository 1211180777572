$search-element-border: 1px solid var(--color-grey);

.search {
  & &__title {
    display: flex;
    align-items: center;
    margin-bottom: 1.5rem;
    color: var(--color-midnight-light);

    .search__title__label {
      font-size: $font-size-20;
      font-weight: $font-weight-semi-bold;
    }
  }

  .search__results {
    border-collapse: separate;
    border-spacing: 0 0.5rem;

    &__head {
      display: table-header-group;
      vertical-align: middle;

      &__column {
        display: table-cell;
        vertical-align: inherit;
        font-size: $font-size-12;
        font-weight: $font-weight-regular;
        color: var(--color-grey-dark);
        text-align: left;
        padding: 0 .5rem;

        &--justify-center {
          text-align: center;
        }
      }
    }

    &__items {
      display: table-row-group;
      vertical-align: middle;
    }

    &__load-more {
      display: flex;
      align-items: center;
      justify-content: center;
      margin: .5rem 0;
      height: 3rem;

      &__button {
        text-transform: initial;
        font-weight: $font-weight-bold;
        background-color: var(--color-white);
        border: 1px solid var(--color-midnight-light);
        color: var(--color-midnight-light);

        &:hover {
          color: var(--color-white);
          background-color: var(--color-midnight-light);
        }
      }

      &__page {
        margin: 0 1rem;
        font-weight: $font-weight-bold;
        color: var(--color-midnight-light);
      }
    }

    .search-element {
      display: table-row;
      vertical-align: inherit;
      text-decoration: none;

      &:hover {
        background-color: var(--color-grey-light);
      }

      .element-column {
        display: table-cell;
        vertical-align: inherit;
        border-top: $search-element-border;
        border-bottom: $search-element-border;
        text-decoration: none;

        &:first-child {
          border-left: $search-element-border;
          border-radius: var(--border-radius-md) 0 0 var(--border-radius-md);
        }

        &:last-child {
          border-right: $search-element-border;
          border-radius: 0 var(--border-radius-md) var(--border-radius-md) 0;
          border-left: $search-element-border;
        }

        .element-column__content,
        .element__content {
          display: flex;
          align-items: center;
          color: var(--color-midnight-light);
          padding: .5rem;

          @media #{$screen-s}, #{$screen-m} {
            padding: .25rem;
          }

          &--justify-center {
            justify-content: center;
          }

          > :not(:last-child) {
            margin-right: .5rem;
          }
        }
      }

      .element__type__icon {
        padding: .25rem;
        font-size: $font-size-20;
        color: var(--color-white);

        @media #{$screen-s}, #{$screen-m} {
          display: none;
        }
      }

      .element__text {
        display: inline-block;
        font-size: $font-size-16;
        line-height: 1.4;

        &--bold {
          font-weight: $font-weight-semi-bold;
        }

        > * {
          vertical-align: middle;
        }

        > :not(:last-child) {
          margin-right: .5rem;
        }

        > i {
          font-size: $font-size-18;
        }

        &__icon-tooltip {
          color: var(--color-grey-dark);

          i {
            font-size: $font-size-18;
          }
        }
      }

      .element__icon {
        i {
          font-size: $font-size-20;
        }
      }

      .element__info {
        padding: .25rem .75rem;
        background-color: var(--color-grey-light);
        border-radius: var(--border-radius-md);
        white-space: nowrap;

        &--highlighted {
          color: var(--color-green);
        }
      }

      .element__actions {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 7rem;
        padding: .25rem .5rem;

        @media #{$screen-s}, #{$screen-m} {
          width: 3rem;
          flex-direction: column;
        }

        &__item {
          display: inline-flex;
          margin: 0.25rem;

          i {
            font-size: $font-size-18;
          }
        }

        &__button {
          display: inline-flex;

          button i:hover {
            color: inherit;
          }

          @media #{$screen-s}, #{$screen-m} {
            button {
              background-color: unset;
              border: 0;
              color: inherit;
              padding: 0;

              i {
                margin: 0;

                &:hover {
                  color: var(--color-green);
                }
              }

              span {
                display: none;
              }
            }
          }
        }
      }
    }
  }
}

