.home {
  & &__campaign-brief {
    display: flex;
    flex-direction: column;
    background-color: var(--color-midnight);
    box-shadow: none !important;
    cursor: pointer;

    & h3 {
      margin: 0;
    }

    & h3,
    & p {
      color: var(--color-grey);
    }

    .campaign-brief__button {
      width: 100%;
      height: 2rem;
      position: relative;
      color: var(--color-green);
      text-align: right;
      text-transform: uppercase;
      font-size: $font-size-18;

      & > * {
        position: absolute;
        transition: all 250ms ease;
      }

      & > span {
        right: 2rem;
      }

      & > i {
        right: 0;
      }
    }

    &:hover .campaign-brief__button {
      & > span {
        right: 2.5rem;
      }

      & > i {
        -webkit-animation-duration: 1s;
        animation-duration: 1s;
        -webkit-animation-fill-mode: both;
        animation-fill-mode: both;
        -webkit-animation-timing-function: ease-in-out;
        animation-timing-function: ease-in-out;
        -webkit-animation-name: headShake;
        animation-name: headShake;
        -webkit-animation-iteration-count: infinite;
        animation-iteration-count: infinite;
      }
    }
  }
}
