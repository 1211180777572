$tree-node-offset: 3rem;
$tree-node-mobile-offset: 1rem;
$tree-item-width: 70rem;
$tree-item-height: 5.5rem;
$tree-link-width: 2px;

.taxonomy-tree {
  & &__loader {
    margin: 0 0.5rem;
    color: var(--color-info-main);
  }

  & &__title {
    display: flex;
    align-items: center;
    margin-bottom: 1.5rem;
    color: var(--color-midnight-light);

    .taxonomy-tree__title__label {
      font-size: $font-size-20;
      font-weight: $font-weight-semi-bold;
    }

    .taxonomy-tree__title__link {
      display: flex;
      align-items: center;
      margin-left: 1rem;
      font-size: $font-size-14;
      font-weight: $font-weight-regular;
      cursor: pointer;

      &:hover span {
        text-decoration: underline;
      }

      i {
        font-size: $font-size-16;
        margin-right: 0.25rem;
      }
    }
  }

  & &__item {
    position: relative;

    .taxonomy-tree__item__block {
      max-width: $tree-item-width;
      min-height: $tree-item-height;

      &:hover {
        background-color: var(--color-grey-light);
      }
    }
  }
}

.taxonomy-node {
  position: relative;

  .node__main {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: var(--color-white);
    color: var(--color-midnight-light);
    border-radius: var(--border-radius-md);
    border: 1px solid var(--color-grey);
    min-height: $tree-item-height + 1rem;
    padding: 0 2rem;
    margin-bottom: 0.5rem;
    cursor: pointer;
    user-select: none;

    &__content {
      display: flex;
      align-items: center;

      > :not(:first-child) {
        margin-left: 0.75rem;
      }
    }

    &__icon {
      font-size: $font-size-28;
    }

    &__name {
      font-size: $font-size-18;
    }

    &__count {
      padding: 0.75rem;
      min-width: 3rem;
      border-radius: var(--border-radius-md);
      background-color: var(--color-ocean);
      color: var(--color-grey-light);
      font-size: $font-size-14;
      text-align: center;
    }

    &__arrow {
      transition: all 0.1s ease-in-out;
    }
  }

  .node__children {
    display: none;
    flex-direction: column;
    margin-left: $tree-node-offset;
    padding-left: $tree-node-offset;
    position: relative;

    &:before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      width: $tree-link-width;
      background-color: var(--color-grey);
      z-index: -1;
    }

    > * {
      &:before {
        content: "";
        position: absolute;
        top: 0;
        left: -$tree-node-offset;
        width: calc($tree-node-offset - $tree-link-width);
        height: calc($tree-item-height / 2 - $tree-link-width);
        border-bottom: $tree-link-width solid var(--color-grey);
        border-left: $tree-link-width solid var(--color-grey);
        border-radius: 0 var(--border-radius-md) 0;
        z-index: -1;
      }

      &:last-child {
        &:before {
          box-shadow: -($tree-link-width * 2) ($tree-link-width * 2) 0 var(--color-white);
        }

        &:after {
          content: "";
          position: absolute;
          top: calc($tree-item-height / 2);
          left: -($tree-node-offset * 2);
          width: $tree-node-offset * 2;
          height: 100%;
          background-color: var(--color-white);
          z-index: -1;
        }
      }
    }

    @media #{$screen-s}, #{$screen-m} {
      margin-left: $tree-node-mobile-offset;
      padding-left: $tree-node-mobile-offset;

      > * {
        &:before {
          left: -$tree-node-mobile-offset;
          width: $tree-node-mobile-offset;
        }

        &:last-child:after {
          left: -($tree-node-mobile-offset * 2);
        }
      }
    }
  }

  &--expanded {
    > .node__main {
      background-color: var(--color-ocean-lighter)!important;
      color: var(--color-ocean);

      .node__main__arrow {
        transform: rotate(90deg);
      }
    }

    > .node__children {
      display: flex;
    }
  }
}
