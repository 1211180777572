.taxonomy-list {
  & &__title {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: var(--spacing-md);
    color: var(--color-midnight-light);

    .taxonomy-list__title__label {
      font-size: $font-size-20;
      font-weight: $font-weight-semi-bold;
    }
  }

  & &__item {
    position: relative;

    .taxonomy-list__item__block {
      &:hover {
        background-color: var(--color-grey-light);
      }
    }
  }

  & &__elements {
    &__load-more {
      display: flex;
      align-items: center;
      justify-content: center;
      margin: .5rem 0;
      height: 3rem;

      &__button {
        text-transform: initial;
        font-weight: $font-weight-bold;
        background-color: var(--color-white);
        border: 1px solid var(--color-midnight-light);
        color: var(--color-midnight-light);

        &:hover {
          color: var(--color-white);
          background-color: var(--color-midnight-light);
        }
      }

      &__page {
        margin: 0 1rem;
        font-weight: $font-weight-bold;
        color: var(--color-midnight-light);
      }
    }
  }
}

