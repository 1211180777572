.step-panel-right {
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    visibility: hidden;
    -webkit-transition: visibility 0s 0.6s;
    transition: visibility 0s 0.6s;
    z-index: 30;
  
    &::after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: transparent;
      cursor: pointer;
      -webkit-transition: background 0.3s 0.3s;
      transition: background 0.3s 0.3s;
    }
  
    .step-panel {
      position: fixed;
      width: 35rem;
      max-width: 100%;
      height: 100%;
      top: 0;
      right: 0;
      background: var(--color-white);
      -webkit-transition: -webkit-transform 0.3s 0.3s;
      transition: transform 0.3s 0.3s;
      -webkit-transform: translate3d(100%, 0, 0);
      transform: translate3d(100%, 0, 0);
      z-index: 31;
  
      .step-panel-content {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        padding: 4rem;
        overflow: auto;
        -webkit-overflow-scrolling: touch;
  
        @media #{$screen-s} {
          padding: 2rem;
        }
  
        .btn-close {
          position: absolute;
          top: 1.5rem;
          right: 1.5rem;
          cursor: pointer;
  
          i {
            font-size: $font-size-28;
            color: var(--color-midnight-light);
          }
        }
  
        .step-panel-header {
          margin-bottom: 3rem;
  
          .step-panel-title {
            padding: 1rem 0;
            line-height: 1.5;
            font-size: $font-size-20;
            font-weight: $font-weight-regular;
            color: var(--color-midnight-light);
          }
  
          .step-panel-progress-bar {
            font-size: $font-size-12;
          }
        }
  
        .step-panel-items {
          padding: 2rem 0;
          border-top: 1px solid var(--color-grey);
          border-bottom: 1px solid var(--color-grey);
  
          .item {
            width: 100%;
            height: 5rem;
            display: grid;
            grid-template-columns: 1fr 6fr;
            align-items: center;
            color: var(--color-midnight-lighter);
  
            input[type="checkbox"] {
              appearance: none;
              -webkit-appearance: none;
              margin: auto 1.5rem auto auto;
              border-radius: 50%;
              height: 2rem;
              width: 2rem;
              border: 2px solid var(--color-midnight-lighter);
              display: flex;
              justify-content: center;
              align-items: center;
            }
  
            input[type="checkbox"] ~ label {
              color: var(--color-midnight-lighter);
            }
  
            input[type="checkbox"]:checked:before {
              content: '\2713';
              font-size: $font-size-14;
              color: var(--color-green);
            }
  
            input[type="checkbox"]:checked {
              border: 2px solid var(--color-green);
            }
  
            input[type="checkbox"]:checked ~ label {
              text-decoration: line-through;
            }
  
            i {
              justify-self: end;
              cursor: pointer;
            }
          }
        }
      }
    }
  
    &.visible {
      visibility: visible;
      -webkit-transition: visibility 0s 0s;
      transition: visibility 0s 0s;
  
      &::after {
        background: rgba(0, 0, 0, 0.7);
        -webkit-transition: background 0.3s 0s;
        transition: background 0.3s 0s;
      }
  
      .step-panel {
        -webkit-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0);
        -webkit-transition-delay: 0s;
        transition-delay: 0s;
      }
    }
  }
  